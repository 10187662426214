import TwtPhotoConfig, { TwtPhotoType } from '~/tenants/twt/performable/photo/TwtPhotoConfig';
import { DisplayData, TenantJobLine, TenantOrderLine } from '~/lib/model';
import { Big } from 'big.js';
import { TwtPropertyType } from '~/tenants/twt/model/TwtOrder';
import { FIRST_VERSION_TIMESTAMP, ZodVersionedMetadata } from '~/lib/zod';
import { PersonaType } from '~/tenants/common/TenantPersona';
import { MicrositeFileType } from '~microsite/lib/types';
import { DeliverableType } from '~common/model/Deliverable';
import { TwtOrderType } from '~/tenants/twt/model/enums';
import { TwtSimpleJob } from '~/tenants/twt/performable/TwtSimpleJob';
import { twt_justin_configured } from '~/tenants/twt/package/forsale/justin/util';
import { twt_version, TwtVersion } from '~/tenants/twt/data';
import { OrderCustomTag } from '~/lib/schema/order';

export const TWT_PHOTO_MINIMUM = 20;
export const TWT_PHOTO_PER_1000 = 5;
export const TWT_PHOTO_TWILIGHT = 199;
export const TWT_PHOTO_NEIGHBORHOOD = 35;
export const TWT_RENTAL_10 = 150;
export const TWT_RENTAL_20 = 200;
export const TWT_PHOTO_EXTRA = 30;
export const TWT_PHOTO_HIGHLIGHT_RESIDENTIAL = 100;
export const TWT_PHOTO_HIGHLIGHT_COMMERCIAL = 200;
export const TWT_PHOTO_DOMAIN = '29.95';

const v4ExpenseMapping: Record<string, string> = {
  '210.00': '205.00',
  '160.00': '155.00',
  '85.00': '90.00',
  '95.00': '90.00',
  '110.00': '105.00',
  '90.00': '85.00',
  '0.00': '20.00',
  '270.00': '290.00',
  '65.00': '75.00',
  '100.00': '95.00',
};

const v4RevenueMapping: Record<string, string> = {
  '575.00': '505.00',
  '185.00': '180.00',
  '245.00': '230.00',
  '200.00': '205.00',
  '215.00': '205.00',
  '455.00': '405.00',
  '425.00': '380.00',
  '335.00': '305.00',
  '100.00': '110.00',
};

export function twt_calculate_photo_images(sqft: number) {
  return Math.floor((sqft - 1) / 1000) * TWT_PHOTO_PER_1000 + TWT_PHOTO_MINIMUM;
}

export default class TwtPhotoJob extends TwtSimpleJob<typeof TwtPhotoConfig> {
  protected get config() {
    return {
      expenseMinimum: 80,
      revenueMinimum: 155,
      revenueFirstHour: 300,
      revenuePer1000: 30,
      onsiteMinimum: 30,
      onsitePer1000: 15,
    };
  }

  get twilight() {
    return Boolean(this.metadata.twilight);
  }

  get performable() {
    return TwtPhotoConfig;
  }

  get tags(): OrderCustomTag[] {
    if (this.twilight) {
      return [
        {
          name: 'Twilight',
          color: 'indigo',
          provider: true,
        },
      ];
    }

    return [];
  }

  get name() {
    return this.metadata.type === TwtPhotoType.RESHOOT || this.metadata.type === TwtPhotoType.RESHOOT_EXTERIOR
      ? 'Reshoot'
      : super.name;
  }

  get deliverable() {
    return DeliverableType.PHOTO;
  }

  get short() {
    return this.metadata.sunrise ? 'DAWN' : this.metadata.twilight ? 'NIGHT' : 'DAY';
  }

  get hourly() {
    return this.order.metadata.type === TwtOrderType.COMMERCIAL && !twt_justin_configured(this.order);
  }

  get libraries(): string[] {
    return this.metadata.libraries ?? [];
  }

  defaultValue(): ZodVersionedMetadata<(typeof TwtPhotoConfig)['schema']> {
    if (
      [TwtOrderType.SHORT_TERM_RENTAL, TwtOrderType.LONG_TERM_RENTAL].includes(this.order.metadata.type) &&
      !twt_version(this.order.created, TwtVersion.V7)
    ) {
      return {
        version: FIRST_VERSION_TIMESTAMP,
        type: TwtPhotoType.INTERIOR_EXTERIOR,
        photos: 20,
      };
    }

    return {
      type: TwtPhotoType.INTERIOR_EXTERIOR,
      version: FIRST_VERSION_TIMESTAMP,
    };
  }

  info(): DisplayData[] {
    const data = super.info();

    data.push({
      name: 'Time of Day',
      value: this.metadata.sunrise ? 'Sunrise' : this.metadata.twilight ? 'Twilight' : 'Daylight',
      provider: true,
      schedule: true,
    });

    if (this.metadata.instructions) {
      data.push({
        name: 'Instructions',
        value: this.metadata.instructions,
        provider: true,
        schedule: true,
      });
    }

    if (this.metadata.neighborhood_custom && this.metadata.neighborhood_custom.length > 0) {
      data.push({
        name: 'Custom Neighborhoods',
        value: this.metadata.neighborhood_custom.map((c) => c.location),
        provider: true,
      });
    }

    if (this.metadata.highlights) {
      data.push({
        name: 'Property Highlights',
        value: 'Yes',
        schedule: true,
        provider: true,
      });
    }

    data.push({
      name: 'Type',
      value: this.calculate().name,
      schedule: true,
      provider: true,
    });

    if (this.photos > 0) {
      data.push({
        name: 'Photos',
        value: this.photos.toString(),
        schedule: true,
        provider: true,
      });
    }

    return data;
  }

  onsite(): number {
    if (this.order.metadata.type === TwtOrderType.COMMERCIAL) {
      return 60;
    }

    let onsite = 0;

    switch (this.metadata.type) {
      case TwtPhotoType.INTERIOR_ONLY:
      case TwtPhotoType.INTERIOR_EXTERIOR:
        onsite = super.onsite();

        if (this.provider) {
          if (this.sqft <= 1000) {
            onsite = this.provider.config.photo_onsite_0_1000 ?? onsite;
          } else if (this.sqft <= 2000) {
            onsite = this.provider.config.photo_onsite_1001_2000 ?? onsite;
          } else if (this.sqft <= 3000) {
            onsite = this.provider.config.photo_onsite_2001_3000 ?? onsite;
          } else if (this.sqft <= 4000) {
            onsite = this.provider.config.photo_onsite_3001_4000 ?? onsite;
          } else if (this.sqft <= 5000) {
            onsite = this.provider.config.photo_onsite_4001_5000 ?? onsite;
          } else if (this.sqft <= 6000) {
            onsite = this.provider.config.photo_onsite_5001_6000 ?? onsite;
          } else {
            const base = this.provider.config.photo_onsite_0_1000 ?? this.config.onsiteMinimum;
            const per1000 = this.provider.config.photo_onsite_every_1000_after_6000 ?? this.config.onsitePer1000;

            onsite = base + per1000 * Math.ceil((this.sqft - 6000) / 1000);
          }
        }

        break;
      case TwtPhotoType.RESHOOT:
      case TwtPhotoType.RESHOOT_EXTERIOR:
        onsite = 30;
        break;
      case TwtPhotoType.EXTERIOR_ONLY:
        onsite = 15;
        break;
    }

    // if (this.metadata.neighborhood) {
    //   onsite += 15;
    // }

    if (this.metadata.highlights) {
      onsite += this.provider?.config.highlights_onsite ?? 30;
    }

    return onsite;
  }

  get configurable() {
    return true;
  }

  get submission() {
    return true;
  }

  get media() {
    return { persona: PersonaType.PROVIDER, microsite: MicrositeFileType.GALLERY };
  }

  get minutes(): number {
    return this.metadata.minutes ?? 0;
  }

  protected expensePer(thousands: number) {
    if (thousands === 0 || thousands === 3) {
      return 10;
    }

    if (thousands < 3) {
      return 5;
    }

    if (thousands < 9) {
      return 20;
    }

    return 10;
  }

  expenseLines(): TenantJobLine[] {
    const lines: TenantOrderLine[] = [];
    let highlights = new Big(79);

    if (this.metadata.libraries && this.metadata.libraries.length > 0) {
      const count = this.metadata.libraries.length === 1 ? '1 Area' : `${this.metadata.libraries.length} Areas`;

      lines.push({
        id: 'neighborhood',
        description: `Stock Neighborhood Amenities (${count})`,
        amount: new Big(25).times(this.metadata.libraries.length),
      });
    }

    const calculated = this.calculate();
    let expense = calculated.expense;

    if (!twt_version(this.order.created, TwtVersion.V4)) {
      highlights = new Big(75);

      if (v4ExpenseMapping[expense.toFixed(2)]) {
        expense = new Big(v4ExpenseMapping[expense.toFixed(2)]);
      }
    }

    if (expense.gt(0)) {
      lines.push({
        id: 'photos',
        description: this.photos > 0 ? `${calculated.name} (${this.photos} Photos)` : calculated.name,
        amount: expense,
      });
    }

    if (
      (!twt_version(this.order.created, TwtVersion.V4) || this.metadata.type === TwtPhotoType.INTERIOR_EXTERIOR) &&
      this.metadata.twilight
    ) {
      lines.push({
        id: 'twilight',
        description: 'Twilight Shoot',
        amount: new Big(150),
      });
    }

    if (this.extraPhotos > 0) {
      lines.push({
        id: 'extra_photos',
        description: `Extra Photos (${this.extraPhotos})`,
        amount: new Big(10).times(this.extraPhotosMultiplier),
      });
    }

    if (this.metadata.neighborhood_custom && this.metadata.neighborhood_custom.length > 0) {
      lines.push({
        id: 'neighborhood_custom',
        description: 'Neighborhood Amenities',
        amount: new Big(25).times(this.metadata.neighborhood_custom.length),
      });
    }

    if (this.metadata.highlights) {
      lines.push({
        id: 'highlights',
        description: 'Property Highlights',
        amount: highlights,
      });
    }

    return lines;
  }

  revenueLines(): TenantJobLine[] {
    const lines: TenantOrderLine[] = [];

    if (this.metadata.domain) {
      lines.push({
        amount: new Big(TWT_PHOTO_DOMAIN),
        description: `Custom Domain (${this.metadata.domain})`,
        id: 'domain',
        taxable: true,
        phase: 'submitted',
      });
    }

    if (this.metadata.type === TwtPhotoType.INTERIOR_EXTERIOR) {
      if (this.metadata.twilight) {
        lines.push({
          id: 'twilight',
          description: 'Twilight Shoot',
          taxable: true,
          amount: new Big(!twt_version(this.order.created, TwtVersion.V4) ? 150 : TWT_PHOTO_TWILIGHT),
        });
      }

      if (this.metadata.sunrise) {
        lines.push({
          id: 'sunrise',
          description: 'Sunrise Shoot',
          taxable: true,
          amount: new Big(TWT_PHOTO_TWILIGHT),
        });
      }
    }

    if (this.extraPhotos > 0) {
      lines.push({
        id: 'extra_photos',
        description: `Extra Photos (${this.extraPhotos})`,
        taxable: true,
        amount: new Big(
          !twt_version(this.order.created, TwtVersion.V4) ||
          this.metadata.type === TwtPhotoType.RESHOOT ||
          this.metadata.type === TwtPhotoType.RESHOOT_EXTERIOR ||
          this.metadata.type === TwtPhotoType.EXTERIOR_ONLY
            ? 25
            : TWT_PHOTO_EXTRA,
        ).times(this.extraPhotosMultiplier),
      });
    }

    if (this.metadata.neighborhood_custom && this.metadata.neighborhood_custom.length > 0) {
      lines.push({
        id: 'neighborhood_custom',
        description: `Custom Neighborhood Amenities (${this.metadata.neighborhood_custom.length} Areas)`,
        taxable: true,
        discountable: true,
        amount: new Big(TWT_PHOTO_NEIGHBORHOOD).times(this.metadata.neighborhood_custom.length),
      });
    }

    if (this.metadata.libraries && this.metadata.libraries.length > 0) {
      const count = this.metadata.libraries.length === 1 ? '1 Area' : `${this.metadata.libraries.length} Areas`;

      lines.push({
        id: 'neighborhood',
        description: `Stock Neighborhood Amenities (${count})`,
        taxable: true,
        discountable: true,
        amount: new Big(TWT_PHOTO_NEIGHBORHOOD).times(this.metadata.libraries.length),
      });
    }

    if (this.metadata.highlights) {
      lines.push({
        id: 'highlights',
        description: 'Property Highlights',
        discountable: true,
        taxable: true,
        amount: new Big(
          this.order.metadata.type === TwtOrderType.COMMERCIAL
            ? TWT_PHOTO_HIGHLIGHT_COMMERCIAL
            : TWT_PHOTO_HIGHLIGHT_RESIDENTIAL,
        ),
      });
    }

    const calculated = this.calculate();

    if (calculated.revenue.gt(0)) {
      let description = calculated.name;

      if (this.photos > 0) {
        description = `${calculated.name} (${this.photos} Photos)`;
      }

      if (this.order.metadata.type === TwtOrderType.COMMERCIAL) {
        description = `Commercial ${calculated.name}`;
      }

      if (this.minutes > 0) {
        description += ` (${this.minutes} Minutes)`;
      }

      lines.push({
        id: 'photos',
        description,
        discountable: true,
        taxable: true,
        amount: calculated.revenue,
      });
    }

    return lines;
  }

  protected get extraPhotos() {
    if (this.photos > 0) {
      return this.metadata.photos && this.metadata.photos > 0 ? this.metadata.photos : 0;
    }

    return 0;
  }

  protected get extraPhotosMultiplier() {
    return Math.ceil(this.extraPhotos / 5);
  }

  get photos() {
    if (this.metadata.photos_override) {
      return this.metadata.photos_override;
    }

    if (this.order.buyer.metadata.package_southstone) {
      return 10;
    }

    if (this.order.metadata.type === TwtOrderType.COMMERCIAL) {
      return 0;
    }

    if (this.order.metadata.subtype == TwtPropertyType.LAND) {
      return 20;
    }

    if (twt_justin_configured(this.order)) {
      return 35;
    }

    if (
      [TwtPhotoType.RESHOOT, TwtPhotoType.EXTERIOR_ONLY, TwtPhotoType.RESHOOT_EXTERIOR].includes(this.metadata.type)
    ) {
      if (this.metadata.photos === -5) {
        return 5;
      }

      return 10;
    }

    if ([TwtOrderType.LONG_TERM_RENTAL, TwtOrderType.SHORT_TERM_RENTAL].includes(this.order.metadata.type) && !twt_version(this.order.created, TwtVersion.V7)) {
      return 20;
    }

    return twt_calculate_photo_images(this.sqft);
  }

  protected calculate() {
    let name: string = 'Daytime Shoot';
    let revenue: Big = new Big(0);
    let expense: Big = new Big(0);

    if (this.metadata.type === TwtPhotoType.RESHOOT || this.metadata.type === TwtPhotoType.RESHOOT_EXTERIOR) {
      name = 'Reshoot';

      if (this.photos === 5) {
        revenue = new Big(75);
        expense = new Big(55);
      } else {
        revenue = new Big(100);
        expense = new Big(65);
      }
    } else if (this.metadata.type === TwtPhotoType.EXTERIOR_ONLY) {
      const land = this.order.metadata.subtype === TwtPropertyType.LAND;

      if (this.metadata.twilight || this.metadata.sunrise) {
        const prefix = this.metadata.sunrise ? 'Sunrise' : 'Twilight';

        name = land ? `${prefix} Land Shoot` : `${prefix} Exterior Only`;
        revenue = this.order.metadata.type === TwtOrderType.COMMERCIAL ? new Big(350) : new Big(249);
        expense = this.order.metadata.type === TwtOrderType.COMMERCIAL ? new Big('227.50') : new Big(190);
      } else if (this.order.metadata.subtype === TwtPropertyType.LAND) {
        revenue = new Big(230);
        expense = new Big(110);
        name = 'Land Shoot';
      } else {
        name = 'Exterior Only';

        if (this.photos === 5) {
          revenue = new Big(75);
          expense = new Big(55);
        } else {
          revenue = new Big(100);
          expense = new Big(65);
        }
      }
    } else if (
      this.order.metadata.type === TwtOrderType.SHORT_TERM_RENTAL &&
      !twt_version(this.order.created, TwtVersion.V7)
    ) {
      revenue = new Big(315);
      expense = new Big(315);

      if (this.photos >= 20) {
        revenue = revenue.plus(TWT_RENTAL_20);
        expense = new Big(615);
      } else if (this.photos >= 10) {
        revenue = revenue.plus(TWT_RENTAL_10);
        expense = new Big(465);
      }
    } else if (
      this.order.metadata.type === TwtOrderType.LONG_TERM_RENTAL &&
      !twt_version(this.order.created, TwtVersion.V7)
    ) {
      revenue = new Big(215);
      expense = new Big(215);

      if (this.photos >= 20) {
        revenue = revenue.plus(TWT_RENTAL_20);
        expense = new Big(515);
      } else if (this.photos >= 10) {
        revenue = revenue.plus(TWT_RENTAL_10);
        expense = new Big(365);
      }
    } else {
      revenue = this.simpleRevenue().amount;

      if (twt_justin_configured(this.order)) {
        expense = new Big('237.50');
      } else {
        expense = this.simpleExpense();

        if (this.provider?.config.senior) {
          const seniorBool = this.provider.config.senior === true;
          const seniorDate =
            typeof this.provider.config.senior === 'string' &&
            new Date(this.order.created) >= new Date(this.provider.config.senior);

          if (seniorBool || seniorDate) {
            expense = expense.plus(10);
          }
        }
      }
    }

    if (!twt_version(this.order.created, TwtVersion.V4)) {
      if (v4RevenueMapping[revenue.toFixed(2)]) {
        revenue = new Big(v4RevenueMapping[revenue.toFixed(2)]);
      }

      if (v4ExpenseMapping[expense.toFixed(2)]) {
        expense = new Big(v4ExpenseMapping[expense.toFixed(2)]);
      }

      if (this.order.metadata.type === TwtOrderType.COMMERCIAL) {
        revenue = new Big(350);
        expense = new Big('227.5');
      }
    }

    if (this.order.buyer.metadata.package_southstone) {
      revenue = new Big(155);
      name = 'Southstone Package';
    }

    return {
      name,
      revenue,
      expense,
    };
  }

  private get interior() {
    return [TwtPhotoType.INTERIOR_EXTERIOR, TwtPhotoType.INTERIOR_ONLY].includes(this.metadata.type);
  }
}
