import { Big } from 'big.js';
import TwtJob from '~/tenants/twt/model/TwtJob';
import { TwtPerformableConfig } from '~/tenants/twt/model/TwtOrder';
import range from 'lodash.range';

interface TwtSimpleJobConfig {
  revenueMinimum?: number;
  revenuePer1000?: number;
  revenueFirstHour?: number;
  expenseMinimum?: number;
  expensePer1000?: number;
  onsiteMinimum: number;
  onsitePer1000: number;
}

export abstract class TwtSimpleJob<P extends TwtPerformableConfig> extends TwtJob<P> {
  protected abstract get config(): TwtSimpleJobConfig;

  protected expensePer(_thousands: number): number | undefined {
    return undefined;
  }

  protected expenseMinimum(): number | undefined {
    return undefined;
  }

  protected revenueMinimum(): number | undefined {
    return undefined;
  }

  protected revenuePer(_thousands: number): number | undefined {
    return undefined;
  }

  protected get revenueFirstHour(): Big {
    return new Big(this.config.revenueFirstHour ?? 0);
  }

  protected get expenseFirstHour(): Big | null {
    return null;
  }

  protected get revenuePerMinute(): Big | null {
    return null;
  }

  protected get expensePerMinute(): Big | null {
    return null;
  }

  get minutes() {
    return 0;
  }

  onsite(): number {
    return this.config.onsiteMinimum + this.sqftMulitpler() * this.config.onsitePer1000;
  }

  protected get extraMinutes() {
    return this.minutes - 60;
  }

  protected simpleExpense() {
    if (this.hourly) {
      let amount = this.expenseFirstHour ?? new Big(0);

      if (this.extraMinutes > 0 && this.expensePerMinute) {
        amount = amount.plus(this.expensePerMinute.times(this.extraMinutes));
      }

      return amount;
    }

    let expense = new Big(this.expenseMinimum() ?? this.config.expenseMinimum ?? 0);

    for (const thousand of range(0, this.sqftMulitpler())) {
      expense = expense.add(new Big(this.expensePer(thousand) ?? this.config.expensePer1000 ?? 0));
    }

    return expense;
  }

  protected simpleRevenue() {
    if (this.hourly) {
      let amount = this.revenueFirstHour;

      if (this.extraMinutes > 0 && this.revenuePerMinute) {
        amount = amount.plus(this.revenuePerMinute.times(this.extraMinutes));
      }

      return {
        amount,
        type: 'hourly' as const,
      };
    }

    let revenue = new Big(this.revenueMinimum() ?? this.config.revenueMinimum ?? 0);

    for (const thousand of range(0, this.sqftMulitpler())) {
      revenue = revenue.add(new Big(this.revenuePer(thousand) ?? this.config.revenuePer1000 ?? 0));
    }

    return {
      amount: revenue,
      type: 'sqft' as const,
    };
  }

  protected sqftMulitpler(_revenue = false) {
    if (this.sqft === 0) {
      return 0;
    }

    return Math.floor((this.sqft - 1) / 1000);
  }
}
