import { createPerformableConfig, TenantId } from '~/tenants/common/registry';
import { coerceLiteralNumberRequired, FIRST_VERSION_TIMESTAMP } from '~/lib/zod';
import z from 'zod';

export default createPerformableConfig(
  'byop',
  {
    [FIRST_VERSION_TIMESTAMP]: z.object({
      version: coerceLiteralNumberRequired(FIRST_VERSION_TIMESTAMP),
      domain: z.string().optional(),
    }),
  },
  {
    name: 'Use Your Own Photos Tour',
    short: 'Have your own photos? We can make a tour out of them!',
    tenant: TenantId.TWIST_TOURS,
    thumbnail: 'tenant/twt/residential/1.jpg',
    images: [],
  },
);
