import { createPerformableConfig, TenantId } from '~/tenants/common/registry';
import { coerceLiteralNumberRequired, FIRST_VERSION_TIMESTAMP } from '~/lib/zod';
import z from 'zod';
import { SCHEMA_FILE } from '~/lib/schema';
import { TwtOrderType } from '~/tenants/twt/model/enums';

export enum TwtAerialType {
  PHOTO_MINI = 'photo_mini',
  PHOTO_FULL = 'photo_full',
  VIDEO = 'video',
  PHOTO_VIDEO = 'photo_video',
}

const schema = z.object({
  version: coerceLiteralNumberRequired(FIRST_VERSION_TIMESTAMP),
  type: z.nativeEnum(TwtAerialType),
  boundaries_photo: z.coerce.number().optional(),
  boundaries_video: z.coerce.number().optional(),
  instructions: z.string().optional(),
  survey: SCHEMA_FILE.optional(),
  pin_text: z
    .object({
      location: z.string(),
      type: z.union([z.literal('photo'), z.literal('video'), z.literal('both')]),
    })
    .array()
    .optional()
    .default([]),
});

export default createPerformableConfig(
  'aerial',
  {
    [FIRST_VERSION_TIMESTAMP]: schema,
  },
  {
    name: 'Aerial Drone',
    short: 'Capture a bird’s eye view with one of our drone experts to enhance the marketing of your property.',
    tenant: TenantId.TWIST_TOURS,
    color(metadata) {
      if (metadata.type === TwtAerialType.VIDEO) {
        return '#29612C';
      }

      return '#60D15F';
    },
    thumbnail(context) {
      if (context['type'] === TwtOrderType.COMMERCIAL) {
        return 'tenant/twt/aerial/commercial.jpg';
      }

      return 'tenant/twt/aerial/mini.jpg';
    },
    images: [],
  },
);
