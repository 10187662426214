import { TwtOrderContext } from '~/tenants/twt/model/TwtOrder';
import TwtPhotoConfig from '~/tenants/twt/performable/photo/TwtPhotoConfig';
import TwtVideoConfig from '~/tenants/twt/performable/video/TwtVideoConfig';
import TwtAerialConfig from '~/tenants/twt/performable/aerial/TwtAerialConfig';
import { twt_version, TwtVersion } from '~/tenants/twt/data';

export const TWT_JONNY_PERFORMABLES = [TwtPhotoConfig, TwtVideoConfig, TwtAerialConfig];

export function twt_jonny_eligible(context: TwtOrderContext) {
  if (context.parent?.package_jonny === true && twt_version(context.created, TwtVersion.V6)) {
    return true;
  }

  return context.buyer.metadata.package_jonny === true && (context.metadata.sqft ?? 0) <= 5000;
}

export function twt_jonny_configured(order: TwtOrderContext) {
  return (
    twt_jonny_eligible(order) && TWT_JONNY_PERFORMABLES.every((p) => order.jobs.some((j) => j.performable_id === p.id))
  );
}
