import { TenantOrderLine } from '~/lib/model';
import Big from 'big.js';
import { FIRST_VERSION_TIMESTAMP, ZodVersionedMetadata } from '~/lib/zod';
import TwtByopConfig from '~/tenants/twt/performable/byop/TwtByopConfig';
import TwtJob from '~/tenants/twt/model/TwtJob';
import { TwtOrderType } from '~/tenants/twt/model/enums';
import { TWT_PHOTO_DOMAIN } from '~/tenants/twt/performable/photo/TwtPhotoJob';

export default class TwtByopJob extends TwtJob<typeof TwtByopConfig> {
  get performable() {
    return TwtByopConfig;
  }

  get assignable() {
    return false;
  }

  get configurable() {
    return true;
  }

  get eligible() {
    return this.order.metadata.type !== TwtOrderType.COMMERCIAL;
  }

  defaultValue(): ZodVersionedMetadata<(typeof TwtByopConfig)['schema']> {
    return {
      version: FIRST_VERSION_TIMESTAMP,
    };
  }

  revenueLines(): TenantOrderLine[] {
    const lines = super.revenueLines();

    if (this.metadata.domain) {
      lines.push({
        amount: new Big(TWT_PHOTO_DOMAIN),
        description: `Custom Domain (${this.metadata.domain})`,
        id: 'domain',
        taxable: true,
        phase: 'completed',
      });
    }

    lines.push({
      amount: new Big('39.95'),
      taxable: true,
      phase: 'completed',
      description: 'Use Your Own Photos Tour',
      id: 'byop',
    });

    return lines;
  }
}
