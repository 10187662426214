import { TenantJob } from '~/tenants/common/TenantJob';
import { TwtOrderContext, TwtPerformableConfig, TwtPropertyType } from '~/tenants/twt/model/TwtOrder';
import TwtProvider from '~/tenants/twt/model/TwtProvider';
import { TwtAerialType } from '~/tenants/twt/performable/aerial/TwtAerialConfig';
import { TwtOrderType } from '~/tenants/twt/model/enums';
import { TwtPhotoType } from '~/tenants/twt/performable/photo/TwtPhotoConfig';

export default abstract class TwtJob<P extends TwtPerformableConfig> extends TenantJob<
  TwtOrderContext,
  P,
  TwtProvider
> {
  protected get orderHasMatterport() {
    return this.order.jobs.some((j) => j.performable_id === '3d');
  }

  protected get orderHasInteriorPhotos() {
    return this.order.jobs.some((j) => j.performable_id === 'photo' && [TwtPhotoType.INTERIOR_ONLY, TwtPhotoType.RESHOOT, TwtPhotoType.INTERIOR_EXTERIOR].includes(j.metadata.type));
  }

  protected get orderHasPhotos() {
    return this.order.jobs.some((j) => j.performable_id === 'photo');
  }

  protected get orderHasFloorplan() {
    return this.order.jobs.some((j) => j.performable_id === 'floorplan');
  }

  protected get orderHasAerialPhoto() {
    return this.order.jobs.some((j) => j.performable_id === 'aerial' && j.metadata.type !== TwtAerialType.VIDEO);
  }

  protected get orderHasAerialVideo() {
    return this.order.jobs.some(
      (j) =>
        j.performable_id === 'aerial' &&
        j.metadata.type !== TwtAerialType.PHOTO_MINI &&
        j.metadata.type !== TwtAerialType.PHOTO_FULL,
    );
  }

  protected get sqft() {
    let sqft = this.order.metadata.sqft ?? 0;

    if (
      this.order.metadata.type === TwtOrderType.FOR_SALE_LISTING &&
      this.order.metadata.subtype === TwtPropertyType.MULTI_FAMILY
    ) {
      sqft = Math.max(4001, sqft);
    }

    return sqft;
  }
}
